import React from 'react';
import {
  Typography, makeStyles, Theme, TypographyProps, Box,
} from '@material-ui/core';
import { motion } from 'framer-motion';
import { ReactComponent as Blob } from '../../images/text-blob.svg';

const colorMap: {[key in Exclude<Extract<TextBlockProps['color'], string>, 'white'>]: keyof Theme['palette']['colors']} = {
  blue: 'gradient_blue',
  purple: 'gradient_purple',
  orange: 'gradient_orange',
};

const useStyles = makeStyles<Theme, Pick<TextBlockProps, 'color'|'align'>>((theme:Theme) => ({
  header: ({ color }) => {
    if (color) {
      return ({
        background: color === 'white' ? theme.palette.common.white : theme.palette.colors[colorMap[color]],
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        textTransform: 'capitalize',
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
      });
    }

    return {
      textTransform: 'capitalize',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    };
  },
  tag: ({ color }) => ({
    marginBottom: theme.spacing(2),
    color: color === 'white' ? 'rgba(255,255,255,0.75)' : theme.palette.text.secondary,
    fontSize: '1rem',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  }),
  body: ({ color }) => ({
    color: color === 'white' ? theme.palette.common.white : theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  }),
  blob: ({ align }) => {
    const rightAlign = align === 'right';

    return ({
      top: 0,
      zIndex: 5,
      position: 'absolute',
      transform: `translate(${rightAlign ? '' : '-'}20%, -40%)${rightAlign ? ' scaleX(-1)' : ''}`,
      [rightAlign ? 'right' : 'left']: 0,
      [theme.breakpoints.down('sm')]: {
        transform: `translate(${rightAlign ? '' : '-'}50%, -40%)${rightAlign ? ' scaleX(-1)' : ''}`,
        [rightAlign ? 'right' : 'left']: '50%',
      },
    });
  },
}));

interface TextBlockProps {
  header: string;
  body: string | React.ReactNode;
  tag?: string;
  align?: TypographyProps['align'];
  color?: 'blue'|'orange'|'purple' | 'white';
  blob?: boolean;
}

const TextBlock = ({
  header, body, tag, align = 'left', color, blob,
}: TextBlockProps) => {
  const classes = useStyles({ color, align });

  return (
    <Box position="relative">
      {blob && (
      <div className={classes.blob}>
        <motion.div animate={{ y: [-5, 5] }} transition={{ repeat: Infinity, repeatType: 'mirror', duration: 2 }}>
          <Blob {...color === 'orange' ? { fill: '#E9855D' } : {}} />
        </motion.div>
      </div>
      )}
      <Box zIndex="10" position="relative">
        {tag && (
        <Typography align={align} variant="caption" classes={{ root: classes.tag }} component="p">
          <b>{tag}</b>
        </Typography>
        )}
        <Typography align={align} variant="h2" gutterBottom classes={{ root: classes.header }}>
          {header}
        </Typography>
        <Typography align={align} variant="body1" classes={{ root: classes.body }}>
          {body}
        </Typography>
      </Box>
    </Box>
  );
};

export default TextBlock;
