import React from 'react';
import {
  Container, Grid, Box, makeStyles, Typography, Theme, useTheme,
} from '@material-ui/core';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { motion } from 'framer-motion';
import Button from '../components/Button';
import TrustedBy from '../components/TrustedBy';
import Header from '../components/Header';
import backdropUrl from '../images/hero-backdrop.svg';
import SwitchText from '../components/SwitchText';

const useStyles = makeStyles((theme:Theme) => ({
  backdrop: {
    background: `url(${backdropUrl})`,
    backgroundSize: '100% 75%',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '1280px 75%',
    },
  },
  button: {
    marginTop: theme.spacing(10),
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '400px',
    },
  },
  header: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  textBlock: {
    marginTop: '20%',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(25),
    },
  },
  tag: {
    fontSize: '1rem',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
  },
  placeholder: {
    animation: `$pulse 750ms ${theme.transitions.easing.easeInOut} alternate infinite`,
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 0.8,
    },
    '100%': {
      opacity: 0.5,
    },
  },
}));

const Hero = () => {
  const classes = useStyles();

  return ((
    <Box className={classes.backdrop} component="section">
      <Container>
        <Grid container justify="center" className={classes.header}>
          <Box
            pt={10}
            pb={5}
            component={(props) => (
              <Grid item xs={8} {...props}>
                <Header />
              </Grid>
            )}
          />
        </Grid>
        <Grid container alignItems="flex-start" spacing={5}>
          <Grid
            item
            lg={6}
            md={7}
            xs={12}
            component={({ className }) => (
              <motion.div className={`${className} ${classes.textBlock}`} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3, delay: 0.1 }}>
                <Typography variant="caption" color="textSecondary" className={classes.tag}><b>My name is</b></Typography>
                <SwitchText options={['Nathan Skelley', 'SkelleyBelly']} />
                <Typography variant="body1" color="textSecondary">
                  {`I'm a software developer with a focus on
                  creating beautiful and engaging user experiences`}
                </Typography>
                <Grid container className={classes.button}>
                  <Grid item xs={8} lg={6}>
                    <Button color="blue" onClick={() => scrollTo('#contact_section')}>
                      Say Hello
                    </Button>
                  </Grid>
                </Grid>

              </motion.div>
            )}
          />
          <Grid
            item
            xs={12}
            md={5}
            lg={6}
            component={({ className }) => (
              <Box className={className} textAlign="right">
                <Image />
              </Box>
            )}
          />
        </Grid>
        <Box mt={5}>
          <TrustedBy />
        </Box>
      </Container>
    </Box>
  ));
};

export default Hero;

const Image = () => {
  const theme = useTheme();
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "hero-mock.png" }) {
        childImageSharp {
          fixed(width: 900,  quality: 60, traceSVG: {color: "#dddaf5", threshold: 250}) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      mobile: file(relativePath: { eq: "hero-mock.png" }) {
        childImageSharp {
          fixed(width: 750,  quality: 60, traceSVG: {color: "#dddaf5", threshold: 250}) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <Img
      loading="eager"
      placeholderClassName={classes.placeholder}
      fixed={[data.mobile.childImageSharp.fixed,
        { ...data.desktop.childImageSharp.fixed, media: `(min-width: ${theme.breakpoints.values.md}px)` }]}
    />
  );
};
