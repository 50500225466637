import React, { useMemo, useRef, useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { motion, Variants } from 'framer-motion';

const useStyles = makeStyles({
  wordContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
});

const letterVariants: Variants = {
  hide: (index:number) => ({
    opacity: 0,
    y: 20,
    transition: {
      delay: 4 + (index * 0.02),
    },
  }),
  show: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.2,
    },
  },
};

interface SwitchTextProps {
    options: Array<string>;
}

const SwitchText = ({ options }: SwitchTextProps) => {
  const classes = useStyles();
  const [hide, setHide] = useState(true);
  const displayTextIndex = useRef<number>(0);

  const longestString = useMemo(() => options.reduce((a, b) => (a.length > b.length ? a : b)), [options]);

  const generateAnimatedWord = (word:string, prevIndex: number, isLastWord:boolean) => (
    <Box key={`${word}-${prevIndex}`} component="span" display="inline-block" mx="0.5rem">
      {Array.from(word).map(
        (letter, letterIndex, { length: wordLength }) => (
          <motion.span
            // eslint-disable-next-line react/no-array-index-key
            key={letterIndex}
            custom={letterIndex + prevIndex}
            variants={letterVariants}
            initial={hide ? 'show' : 'hide'}
            animate={hide ? 'hide' : 'show'}
            style={{ display: 'inline-block' }}
            onAnimationComplete={() => {
              if (letterIndex === wordLength - 1 && isLastWord) {
                // if the text is hidden, change it to the new name
                if (hide) {
                  displayTextIndex.current = (displayTextIndex.current + 1) % options.length;
                }

                setHide(!hide);
              }
            }}
          >
            {letter}
          </motion.span>
        ),
      )}
    </Box>
  );

  const [animatedWords] = options[displayTextIndex.current].split(' ').reduce(
    ([words, letterCount], word, wordIndex, { length: numOfWords }) => [
      [...words, generateAnimatedWord(word, letterCount, wordIndex === numOfWords - 1)],
      letterCount + word.length], [[], 0] as any,
  );

  return (
    <Box position="relative">
      <Typography variant="h1" gutterBottom align="center" className={classes.wordContainer}>
        {animatedWords}
      </Typography>

      {/* dummy text to determine correct space and height to render main text into */}
      <Typography variant="h1" component="span" style={{ visibility: 'hidden' }}>
        {longestString.split(' ').map(
          // eslint-disable-next-line react/no-array-index-key
          (word, index) => <Box key={`${word}-${index}`} component="span" mx="1rem" display="inline-block">{word}</Box>,
        )}
      </Typography>
    </Box>
  );
};

export default SwitchText;
