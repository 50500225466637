import React, { useMemo, useState } from 'react';
import {
  makeStyles, Theme, Grid, Typography, Box,
} from '@material-ui/core';
import {
  Formik, Field, Form, FieldConfig,
} from 'formik';
import { TextField, TextFieldProps } from 'formik-material-ui';
import * as Yup from 'yup';
import Card from '../Card';
import Button from '../Button';
import { ReactComponent as SuccessIcon } from '../../images/success-icon.svg';

const FORM_SPACING = 5;
const FORM_NAME = 'contact';

const useStyles = makeStyles<Theme, {sent: boolean}>((theme:Theme) => {
  const FORM_TRANSITION_TIMING = theme.transitions.duration.complex;

  return ({
    inputRoot: {
      borderRadius: '4px',
      backgroundColor: '#F5F5F5',
      '&:hover': {
        backgroundColor: '#E8E8E8',
      },
    },
    labelRoot: {
      fontWeight: 700,
    },
    formWrapper: ({ sent }) => ({
      width: '200%',
      display: 'flex',
      marginBottom: 0,
      transition: `transform ${FORM_TRANSITION_TIMING}ms ${theme.transitions.easing.easeInOut}`,
      transform: sent ? 'translateX(-50%)' : undefined,
    }),
    form: {
      width: `calc(50% + ${theme.spacing(FORM_SPACING)}px)`,
    },
    successAlert: {
      width: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    successHeader: {
      marginTop: theme.spacing(5),
      maxWidth: '60%',
    },
    successBody: {
      maxWidth: '60%',
    },
    successIcon: ({ sent }) => ({
      transition: `transform ${FORM_TRANSITION_TIMING}ms ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
      transform: sent ? undefined : 'scale(0)',
    }),
  });
});

const validationSchema = Yup.object().shape({
  name: Yup.string().trim()
    .required('Please provide your name (so I know what to call you)'),
  email: Yup.string().trim()
    .email("That email doesn't look valid. Are you sure it's correct?")
    .required('Please add an email address I can contact you at'),
  message: Yup.string().trim()
    .required('What would you like to talk about?'),
});

const encode = (data: Record<string, string>) => Object.keys(data)
  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
  .join('&');

const ContactForm = () => {
  const [sent, setSent] = useState<boolean>(false);
  const classes = useStyles({ sent });

  const sharedProps: Omit<FieldConfig<TextFieldProps>, 'name'> & Partial<TextFieldProps> = useMemo(() => ({
    component: TextField,
    fullWidth: true,
    variant: 'filled',
    InputProps: { className: classes.inputRoot, disableUnderline: true },
    InputLabelProps: { className: classes.labelRoot },

  }), [classes]);

  return (
    <Formik
      initialValues={{ name: '', email: '', message: '' }}
      validateOnBlur={false}
      onSubmit={(values) => {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': FORM_NAME, ...values }),
        })
          .then(() => {
            setSent(true);
          });
        // .catch((error) => alert(error));
      }}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => {
        const disabled = isSubmitting || sent;

        return (
          <Card>
            <Box overflow="hidden" pb={5}>
              <Form className={classes.formWrapper} name={FORM_NAME} data-netlify="true">
                <Grid container spacing={FORM_SPACING} justify="center" className={classes.form}>
                  <Grid item xs={12}>
                    <Typography variant="h2">Drop me a line</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="name" label="Name" {...sharedProps} disabled={disabled} id="contact-name" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="email" type="email" label="Email" {...sharedProps} disabled={disabled} id="contact-email" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="message" label="Message" multiline rows={10} {...sharedProps} disabled={disabled} id="contact-message" />
                  </Grid>
                  <Grid item xs={8} sm={6}>
                    <Button color="green" type="submit" disabled={disabled}>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
                <Box className={classes.successAlert}>
                  <SuccessIcon width="40%" className={classes.successIcon} />
                  <Typography align="center" variant="h2" className={classes.successHeader}>
                    <b>Form Submitted</b>
                  </Typography>
                  <Typography align="center" variant="body1" className={classes.successBody}>
                    Thanks for getting in touch!
                    <br />
                    I&apos;ll get back to you as soon as possible.
                  </Typography>
                </Box>
              </Form>
            </Box>
          </Card>
        );
      }}
    </Formik>
  );
};

export default ContactForm;
