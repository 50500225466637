import React from 'react';
import {
  Box, Container, Grid, makeStyles, Theme,
} from '@material-ui/core';
import { useInView } from 'react-intersection-observer';
import { motion, Variants } from 'framer-motion';
import TextBlock from '../components/TextBlock';
import { ReactComponent as SkillsBackdrop } from '../images/skills-backdrop.svg';
import SkillBlock from '../components/SkillBlock';

const useStyles = makeStyles((theme:Theme) => ({
  container: {
    paddingTop: '20px',
    [theme.breakpoints.up('md')]: {
      paddingTop: '100px',
    },
  },
  backdrop: {
    height: '100%',
    position: 'absolute',
    zIndex: 5,
    transform: 'translateY(-20px)',
    [theme.breakpoints.only('xs')]: {
      transform: 'translate(-10%, 10px) scale(1.0)',
    },
    [theme.breakpoints.only('sm')]: {
      transform: 'translate(20%, 10px) scale(1.2)',
    },
    [theme.breakpoints.up('md')]: {
      transform: 'translateY(-100px)',
    },
  },
  root: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(20),
    [theme.breakpoints.only('sm')]: {
      marginBottom: theme.spacing(30),
    },
  },
  textBlock: {
    marginTop: '50px',
    [theme.breakpoints.up('md')]: {
      marginTop: '100px',
    },
  },
  cardWrapper: {
    zIndex: 10,
    position: 'relative',
  },
  card: {
    marginBottom: theme.spacing(4),
  },
}));

const wrapperVariants:Variants = {
  visible: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
    },
  },
};

const cardVariants:Variants = {
  hidden: {
    opacity: 0,
    x: 20,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
};

const About = () => {
  const classes = useStyles();
  const { ref, inView } = useInView({ rootMargin: '100% 0px -25% 0px', triggerOnce: true });

  return (
    <Box overflow="hidden" id="skills_section" component="section">
      <Container className={classes.root}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4} lg={5}>
            <Box className={classes.textBlock}>
              <TextBlock
                blob
                tag="my skills"
                color="purple"
                header="What do I do?"
                body="In short? I build awesome user experiences for the web and mobile."
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={8} lg={7}>
            <Box position="relative" className={classes.container}>
              <SkillsBackdrop className={classes.backdrop} />
              <motion.div ref={ref} className={classes.cardWrapper} animate={inView ? 'visible' : 'hidden'} variants={wrapperVariants}>
                <motion.div className={classes.card} variants={cardVariants}>
                  <SkillBlock
                    icon="wifi"
                    header="UI Design"
                    body="I create beautiful, clean and eye-catching designs that suit any kind of message"
                  />
                </motion.div>
                <motion.div className={classes.card} variants={cardVariants}>
                  <SkillBlock
                    icon="monitor"
                    header="Websites and Applications"
                    body="I build engaging websites and applications using the latest and greatest technologies"
                  />
                </motion.div>
                <motion.div variants={cardVariants}>
                  <SkillBlock
                    icon="credit"
                    header="Business Process Design"
                    body="I cut wasted time and effort out of day-to-day processes to get more done in fewer hours"
                  />
                </motion.div>
              </motion.div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default About;
