import React, { ReactNode } from 'react';
import {
  Box, makeStyles, Theme,
} from '@material-ui/core';

interface CardProps {
    children: ReactNode
}

const useStyles = makeStyles((theme:Theme) => ({
  root: {
    backgroundColor: 'white',
    padding: theme.spacing(7, 6),
    borderRadius: '16px',
    boxShadow: 'rgba(0,0,0,0.1) 0px 4px 10px',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(15, 12),
    },
  },
}));

const Card = ({ children }: CardProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>{children}</Box>
  );
};
export default Card;
