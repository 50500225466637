import React from 'react';
import {
  Typography, Grid, makeStyles, Theme,
} from '@material-ui/core';
import { useInView } from 'react-intersection-observer';
import { motion, Variants } from 'framer-motion';
import rollsRoyce from '../../images/rolls-royce.svg';
import deloitte from '../../images/deloitte.svg';
import ibm from '../../images/ibm.svg';
import andDigital from '../../images/and-digital.svg';

const useStyles = makeStyles((theme:Theme) => ({
  baseLogo: {
    maxHeight: '75px',
    filter: 'grayscale(1) opacity(0.8)',
    transition: `all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
    '&:hover': {
      filter: 'none',
      transform: 'scale(1.05)',
    },
    [theme.breakpoints.down('xs')]: {
      flexBasis: '100%',
      maxWidth: '100%',
      padding: '20px 20%',
    },
    [theme.breakpoints.only('sm')]: {
      flexBasis: '50%',
      maxWidth: '50%',
      padding: theme.spacing(4, 5),
    },
    [theme.breakpoints.up('md')]: {
      flexBasis: '20%',
      maxWidth: '20%',
      padding: 0,
    },
  },
  deloitteLogo: {
    filter: 'grayscale(1) opacity(0.4)',
  },
  ibmLogo: {
    [theme.breakpoints.only('xs')]: {
      padding: '10px 20%',
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(7),
      padding: '1% 0',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
  },
  container: {
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
  },
}));

const wrapperVariants:Variants = {
  visible: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.05,
    },
  },
};

const headerVariants:Variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const imageVariants:Variants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const TrustedBy = () => {
  const classes = useStyles();
  const { ref, inView } = useInView({ rootMargin: '500px 0px -100px 0px', triggerOnce: true });

  return (
    <motion.div ref={ref} animate={inView ? 'visible' : 'hidden'} variants={wrapperVariants}>
      <Typography align="center" color="textSecondary" paragraph>
        <motion.b variants={headerVariants}>WORKED WITH</motion.b>
      </Typography>
      <Grid container justify="space-between" className={classes.container}>
        <motion.img variants={imageVariants} whileHover={{ scale: 1.05 }} src={rollsRoyce} alt="Rolls Royce Plc." className={`${classes.baseLogo}`} />
        <motion.img variants={imageVariants} whileHover={{ scale: 1.05 }} src={ibm} alt="IBM" className={`${classes.baseLogo} ${classes.ibmLogo}`} />
        <motion.img variants={imageVariants} whileHover={{ scale: 1.05 }} src={deloitte} alt="Deloitte" className={`${classes.baseLogo} ${classes.deloitteLogo}`} />
        <motion.img variants={imageVariants} whileHover={{ scale: 1.05 }} src={andDigital} alt="AND Digital" className={`${classes.baseLogo}`} />
      </Grid>
    </motion.div>
  );
};

export default TrustedBy;
