import React from 'react';
import {
  Box, makeStyles, Button, Theme,
} from '@material-ui/core';
import scrollTo from 'gatsby-plugin-smoothscroll';

const useStyles = makeStyles((theme:Theme) => ({
  root: {
    ...theme.typography.body2,
    textTransform: 'none',
    fontWeight: 700,
    color: theme.palette.text.secondary,
    transition: `all ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeInOut}`,
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      transform: 'scale(1.1)',
    },
  },
  card: {
    padding: theme.spacing(4, 20),
    backgroundColor: theme.palette.common.white,
    borderRadius: 16,
    boxShadow: 'rgba(0,0,0,0.1) 0px 4px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 10),
    },
  },
}));

const links = {
  About: '#about_section',
  Skills: '#skills_section',
  Projects: '#projects_section',
  Contact: '#contact_section',
};

const Header = () => {
  const classes = useStyles();

  return (
    <Box component="nav" className={classes.card}>
      {Object.entries(links).map(([text, link]) => (
        <Button key={text} onClick={() => scrollTo(link)} variant="text" className={classes.root} disableTouchRipple disableFocusRipple>
          {text}
        </Button>
      ))}

    </Box>
  );
};

export default Header;
