import React from 'react';
import {
  Box, Container, Grid, makeStyles, Theme, useTheme,
} from '@material-ui/core';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { motion, Variants } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import TextBlock from '../components/TextBlock';

const useStyles = makeStyles((theme:Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  placeholder: {
    animation: `$pulse 750ms ${theme.transitions.easing.easeInOut} alternate infinite`,
  },
  container: {
    marginTop: theme.spacing(15),
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 0.8,
    },
    '100%': {
      opacity: 0.5,
    },
  },
}));

const textVariants:Variants = {
  hidden: {
    opacity: 0,
    x: 20,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
};

const About = () => {
  const classes = useStyles();
  const { ref, inView } = useInView({ rootMargin: '100% 0px -25% 0px', triggerOnce: true });

  return (
    <Box mt={15} id="about_section" component="section">
      <Container>
        <Grid container alignItems="center" spacing={5} className={classes.root}>
          <Grid item xs={12} md={5}>
            <Image />
          </Grid>
          <Grid item xs={12} md={7}>
            <motion.div ref={ref} animate={inView ? 'visible' : 'hidden'} variants={textVariants}>
              <TextBlock
                blob
                color="orange"
                tag="about me"
                align="right"
                header="Happiest in snow and code"
                body={(
                  <>
                    <span>
                      {`I've been coding in one shape or another for almost a decade now, and I've loved (almost) every second of it.
                I'm always on the lookout for the latest and greatest technologies, and take every opportunity to get my hands on new and exciting challenges.`}
                    </span>
                    <br />
                    <br />
                    <span>
                      {`Unlike most developers, I never studied computer science, instead I opted for a Masters in Engineering from the University of Bath.
                  Beyond that, the only important things to know are that I'm based London, I'm never happier than when I'm flying down a mountain on
                  my snowboard, and that I'm a massive foodie (my Yaki Udon is actually pretty good!)`}
                    </span>
                  </>
            )}
              />
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default About;

const Image = () => {
  const theme = useTheme();
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "snowboard-mock.png" }) {
        childImageSharp {
          fixed(width: 900, quality: 100, traceSVG: {color: "#f5e3da", threshold: 250}) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      mobile: file(relativePath: { eq: "snowboard-mock.png" }) {
        childImageSharp {
          fixed(width: 750, quality: 100, traceSVG: {color: "#f5e3da", threshold: 250}) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <Img
      placeholderClassName={classes.placeholder}
      fixed={[data.mobile.childImageSharp.fixed,
        { ...data.desktop.childImageSharp.fixed, media: `(min-width: ${theme.breakpoints.values.md}px)` }]}
      style={{ float: 'right' }}
    />
  );
};
