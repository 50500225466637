import React from 'react';
import {
  Typography, Box, makeStyles, Theme,
} from '@material-ui/core';
import { ReactComponent as Wifi } from '../../images/skill_1.svg';
import { ReactComponent as Credit } from '../../images/skill_2.svg';
import { ReactComponent as Monitor } from '../../images/skill_3.svg';

const useStyles = makeStyles((theme:Theme) => ({
  icon: {
    flexShrink: 0,
    width: '60px',
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(4),
    },
  },
  card: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(5),
    borderRadius: '16px',
    boxShadow: 'rgba(0,0,0,0.1) 0px 4px 10px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center',
      flexDirection: 'column',
    },
  },
}));

const iconMap = {
  wifi: Wifi,
  credit: Credit,
  monitor: Monitor,
};

interface TextBlockProps {
  header: string;
  body: string;
  icon?: keyof typeof iconMap;
}

const TextBlock = ({
  header, body, icon,
}: TextBlockProps) => {
  const classes = useStyles();

  let iconElement = null;
  if (icon) {
    const Icon = iconMap[icon];
    iconElement = <Icon className={classes.icon} />;
  }

  return (
    <Box className={classes.card}>
      {iconElement}
      <Box>
        <Typography variant="body1"><b>{header}</b></Typography>
        <Typography variant="body1" color="textSecondary">{body}</Typography>
      </Box>
    </Box>
  );
};
export default TextBlock;
