import React from 'react';
import { Box } from '@material-ui/core';
import About from '../sections/About';
import Hero from '../sections/Hero';
import Skills from '../sections/Skills';
import Project from '../sections/Project';
import Contact from '../sections/Contact';
import SEO from '../components/seo';
import ThemeProvider from '../components/ThemeProvider';

const IndexPage = () => (
  <ThemeProvider>
    <Box component="main">
      <SEO title="Nathan Skelley - Full-Stack JavaScript Developer" />
      <Hero />
      <About />
      <Skills />
      <Project />
      <Contact />
    </Box>
  </ThemeProvider>
);

export default IndexPage;
