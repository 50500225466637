import React from 'react';
import {
  Box, Container, Grid, makeStyles, Typography, Theme,
} from '@material-ui/core';
import { useInView } from 'react-intersection-observer';
import { motion, Variants } from 'framer-motion';
import TextBlock from '../components/TextBlock';
import backdropUrl from '../images/contact-backdrop.svg';
import Form from '../components/Form';

const useStyles = makeStyles((theme:Theme) => ({
  backdrop: {
    background: `url(${backdropUrl})`,
    backgroundRepeat: 'no-repeat',
    paddingTop: '80px',
    marginTop: '40px',
    paddingBottom: '40px',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '1000% 100%',
      backgroundPositionX: '75%',
    },
    [theme.breakpoints.only('md')]: {
      backgroundSize: '200% 100%',
      backgroundPositionX: '50%',
    },
    [theme.breakpoints.only('lg')]: {
      backgroundSize: '150% 100%',
      backgroundPositionX: '25%',
    },
  },
  copyright: {
    color: theme.palette.common.white,
    marginTop: '40px',
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  textBlock: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(15),
    },
  },
}));

const formVariants:Variants = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const About = () => {
  const classes = useStyles();
  const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: true });

  return (
    <Box id="contact_section" className={classes.backdrop} component="section">
      <Container disableGutters>
        <Grid container alignItems="center" spacing={5} className={classes.container}>
          <Grid item xs={12} md={7}>
            <motion.div ref={ref} animate={inView ? 'visible' : 'hidden'} variants={formVariants}>
              <Form />
            </motion.div>
          </Grid>
          <Grid item xs={10} md={5} className={classes.textBlock}>
            <TextBlock
              color="white"
              align="right"
              tag="contact me"
              header="Building something cool? Count me in!"
              body={"Big or small, it's always good to hear about new projects, so pop some mail in my inbox."}
            />
          </Grid>
        </Grid>
      </Container>
      <Typography align="center" variant="body2" className={classes.copyright}>{`© ${new Date().getFullYear()} Nathan Skelley`}</Typography>
    </Box>
  );
};

export default About;
