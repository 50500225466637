import React from 'react';
import {
  Box, Container, Grid, makeStyles, Typography, Theme, useMediaQuery,
} from '@material-ui/core';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useInView } from 'react-intersection-observer';
import { motion, Variants } from 'framer-motion';
import TextBlock from '../components/TextBlock';
import { ReactComponent as ProjectBackdrop } from '../images/project-backdrop.svg';

const useStyles = makeStyles((theme:Theme) => ({
  container: {
    paddingTop: theme.spacing(8),
  },
  header: {
    marginBottom: '100px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(10),
    },
  },
  textBlock: {
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('md')]: {
      color: 'white',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  blob: {
    position: 'absolute',
    minHeight: '100%',
    minWidth: '100%',
    top: 0,
    left: '50%',
    transform: 'translate(-50%, 20px) scale(1.5)',
    zIndex: -1,
    [theme.breakpoints.only('md')]: {
      maxHeight: '450px',
      transform: 'translate(-50%, 20px) scale(1.6)',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  sticky: {
    paddingBottom: '30px',
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      top: '20%',
      paddingBottom: '100px',
      zIndex: -1,
    },
  },

  percentage: {
    fontSize: '3.5rem',
  },
  placeholder: {
    animation: `$pulse 750ms ${theme.transitions.easing.easeInOut} alternate infinite`,
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 0.8,
    },
    '100%': {
      opacity: 0.5,
    },
  },
}));

const textVariants:Variants = {
  hidden: {
    opacity: 0,
    x: -20,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
};

const Project = () => {
  const classes = useStyles();
  const isMd = useMediaQuery((theme:Theme) => theme.breakpoints.up('md'));
  const { ref, inView } = useInView({ rootMargin: '100% 0px -400px 0px', triggerOnce: true });

  return (
    <Container id="projects_section" component="section" className={classes.container}>
      <Grid container justify="center" className={classes.header}>
        <Grid item xs={12} md={10} lg={8}>
          <TextBlock
            align="center"
            tag="my latest project"
            color="blue"
            header="Digital transformation of a renewable energy installer"
            body={`Eco NRG Ltd. is a fast-growing renewable energy installer based in Devon, and while their green solutions were moving the UK towards
            a brighter future, their website was stuck in the past!`}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6} lg={5}>
          <Box className={classes.sticky}>
            <motion.div ref={ref} animate={inView || !isMd ? 'visible' : 'hidden'} variants={textVariants} style={{ position: 'relative', zIndex: -1 }}>
              <ProjectBackdrop className={classes.blob} />
              <Box className={classes.textBlock}>
                <Typography variant="h2" gutterBottom>
                  <b>
                    A
                    <span className={classes.percentage}> 300</span>
                    % increase in website traffic
                  </b>
                </Typography>
                <Typography variant="body1" paragraph>
                  A complete rebrand, spearheaded by a slick new website saw a 300% increase in traffic for Eco NRG.
                </Typography>

                <Typography variant="body1">
                  {`Promiment testimonials, clear technical information and interactive savings calculators showcased the
                  company's industry-leading quality and produced well-informed customers that drove higher conversion rates.`}
                </Typography>
              </Box>
            </motion.div>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={7}>
          {/* <img alt="" src={ecoSite} /> */}
          <Image />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Project;

const Image = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "eco-site.png" }) {
        childImageSharp {
          fluid(traceSVG: {color: "rgba(219, 247, 116,0.6)", threshold: 255}) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <Img
      placeholderClassName={classes.placeholder}
      fluid={data.file.childImageSharp.fluid}
      style={{ filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25))' }}
    />
  );
};
